import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Footer from './components/global/navbar/footer/Footer';
import Menu from './components/global/navbar/Navbar';
import Home from './components/pages/Home/Home';


function App() {
  return (
    <div>
      <Menu/>
      <main>
        <Home/>
      </main>
     <Footer/>
    </div>
  );
}

export default App;
